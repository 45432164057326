<script setup>
/*
    Implementing the Game logic.

    TODO
    - Show that an opponent disconnected
    - 
*/
import ReconnectingWebSocket from 'reconnecting-websocket';

import Board from './Board.vue'
import Chat from './Chat.vue'
import Ping from './Ping.vue'
import ChatWindow from './ChatWindow.vue'
import GameSidebar from './GameSidebar.vue'

import { Match } from '../assets/js/match.js'
import { StateMachine } from '../assets/js/statemachine.js'
import {BoardState} from '../assets/js/board.js'
import {ref, nextTick, computed, reactive, onMounted, onUnmounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'

import {useChatStore } from '@/stores/chatstore.js'
const chatStore = useChatStore();

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import {useGameSettingStore} from '@/stores/gamesettingstore.js'
const gameSettingStore = useGameSettingStore();

const route = useRoute();
const router = useRouter();

const play_server = import.meta.env.VITE_WEBSOCKET_SERVER;
const app_server = import.meta.env.VITE_APP_SERVER;

const state = reactive({
    currentState: null,
});

const extra_data = reactive({
    message : "",
    board_message : "",
    match_info: {},
    connected: false,
    finalized: false,
    auto_move: route.query.auto_move,
    last_game: false,
    autoroll: false,
    forced_move: false,
});

const latency_data = reactive({
    show: true,
    latency_w: null,
    latency_b: null,
    server: null,
    last_update: null,
});

const clock_data = reactive({
    clock: {},
    clock_config: {},
});

const premove_data = reactive({
    premoves: [],
});

const chat_data = reactive({
    lobbies: [],
});
const match = new Match();
const state_machine = reactive(new StateMachine());

let window_id = null;
let move_socket = null;
var finalize_timeout = null;
var ping_interval_id = null;
var ping_timeout = null;

state_machine.roll_dice_callback = (dice, move_counter, distinct) => match.get_dice(dice, move_counter, distinct);

onMounted(async () => {
    const url_match_id = route.params.match_id;
    match.match_id = url_match_id

    userStore.loadPreferences().then( () => {
        userStore.applyBoardPreferences(document.getElementById("game"))
    });
    
    const force_connect = route.query.force || false;
    console.log("FORCE CONNECT:", force_connect);
    
    if(force_connect){
        router.replace({query: null});
    }

    // Load the match from localstorage
    load_match();
    
    await get_match_info(match.match_id);
    
    if(match.get_token() == null){
        console.log("Error: no player token");
    }
    
    if(match.match_id == null){
        console.log("ERROR: Match has no id");
    }else{
        connectMatch(force_connect);
    }
});
 
onUnmounted( () => {
    if(move_socket){
        move_socket.close(); 
    }
    if(ping_interval_id){
        clearInterval(ping_interval_id);
        ping_interval_id = null;
    }
});

function load_match(match_dict=null){
    /*
        Loads the match from localstorage. If the match id in the local storage
        is equal to the match stored in match_dict -> copy the secrets from local
        storage.
    */
    // First we load the stored match
    var stored_match = new Match();
    const stored_match_str = localStorage.getItem('current_match');
    if(stored_match_str != null){
        console.log("Loading match from local storage");
        stored_match.from_json(JSON.parse(stored_match_str));
    }
    
    // If we are given a new match dict, update the current match
    if(match_dict != null){
        console.log("Received matchdict", match_dict);
        match.from_json(match_dict)
    }
    
    if(stored_match.match_id == match.match_id){
        console.log("STORED:", stored_match.get_secret());
        match.set_secret(stored_match.get_secret());
        match.set_token(stored_match.get_token());
    }else{
        console.log("Stored match different", stored_match.match_id, match.match_id);
    }
    
    // store the match into the amtch registry
    console.log(match.to_json());
    localStorage.setItem('current_match', JSON.stringify(match.to_json()));
}

async function get_match_info(match_id){
    const response = await fetch(app_server + `/match/${ match_id }/`, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    const match_data = await response.json();
    load_match(match_data.match);

    console.log("MATCH DATA", match_data);
    extra_data.match_info = match_data.info;

    match.set_token(match_data.player.token);
    match.set_player_color(match_data.player.color);
    localStorage.setItem('current_match', JSON.stringify(match.to_json()));

    state_machine.player_color = match_data.player.color;
    state_machine.initial_board = new BoardState(match_data.match.initial_state);
    state_machine.limit = match_data.match.limit;
    console.log("Set the state_machine", state_machine);

    chat_data.lobbies = [match_data.chat_key];

    console.log(match_data.info);
}

function getDiceString(move_id){
    return match.get_dice_string(move_id);
}

function getMoveString(move_id){
    return match.get_move_string(move_id);
}

function sendMessage(positionString, type="move", extra_data=null){
    // send the move to the other player
    let signature = match.sign_position(positionString);
    if(move_socket && move_socket.readyState == 0){// the socket is connecting
        return;
    }else if(move_socket){
        const data = {
            type: type, 
            match_id: match.match_id, 
            extra_data: extra_data,
        };

        if(type != "finalize"){
            data.state = positionString;
            data.signature = signature;
            // match.push_state(new BoardState(positionString), signature);
        }

        var message = JSON.stringify(data);
        console.log("SEND:", data);
        move_socket.send(message);
    
        if(type == "finalize"){ // We wait for 10 seconds for the opponent to finalize, otherwise move on
            console.log("FINALIZE");
            finalize_timeout = setTimeout(() => {
                    console.log("Going to POST");
                    set_board_message("Redirecting", -1);
                    router.replace({name:"post", params:{match_id: match.match_id}})
                }, 10000
            );
         }
    }else{
        console.error("Socket not open");
        connectMatch();
    }
    return;
}

function ping_server(){
    if(!extra_data.connected){
        console.log("Not yet connected, wait.");
        return;
    }
    const data = {
        type: "ping", 
    };
    var message = JSON.stringify(data);
    clearTimeout(ping_timeout);
    ping_timeout = setTimeout(() => set_board_message("Disconnected", 10000), 3000);
    if(move_socket){
        move_socket.send(message);
    }else{
        console.error("Websocket closed, coud not send ping");
    }
}

function sendLastGame(message){
    if(move_socket){
        message.match_id = match.match_id;
        message.type = "lastgame";

        var data_json = JSON.stringify(message);
        move_socket.send(data_json);
        
        set_board_message("Last Game");
        extra_data.last_game = true;
    }else{
        console.error("Socket not open");
    }
    return;
}

function sendFlag(){
    if(move_socket){
        const message = {};
        message.match_id = match.match_id;
        message.type = "flag";

        var data_json = JSON.stringify(message);
        move_socket.send(data_json);
    }else{
        console.error("Socket not open");
    }
    return;
}

function handleMove(positionString, action=null){
    const boardstate = new BoardState(positionString);

    if(!boardstate.is_lastgame && extra_data.last_game){
        boardstate.is_lastgame = true;
    }

    if(extra_data.forced_move){ // We are in a forced move, abort
        return false;
    }
    
    if( action == "flag"){
        sendFlag();
        return;
    }    

    if( action == "lastgame"){
        sendLastGame({});
        return;
    }    

    if(action == "finalize"){
        const extra_data = match.to_json().player;
        sendMessage(boardstate.toPositionString(), "finalize", extra_data);
        return;
    }
    console.log("HANDLE MOVE", positionString);
    
    if( extra_data.autoroll && state_machine.can_double(boardstate)){
        action = "roll";
    }
    if(boardstate.game_state == "G" || boardstate.game_state == "IW"){
        extra_data.autoroll = false;
    }

    var new_boardstate = state_machine.next_state(boardstate, action);     

    if(new_boardstate == null){
        new_boardstate = boardstate;
    }else{
        if(new_boardstate.game_state == "F"){
            const extra_data = match.to_json().player;
            sendMessage(new_boardstate.toPositionString(), "finalize", extra_data);
        }else{
            sendMessage(new_boardstate.toPositionString());
        }
        state.currentState = new_boardstate.toPositionString();
        return true;
    }
    state.currentState = new_boardstate.toPositionString();
    return false;
}

async function handlePremove(premove){
    if(premove == null){
        premove_data.premoves.length = 0;
    }
    else if(premove == "undo"){
        premove_data.premoves.pop();
    }else{ 
        premove_data.premoves.push(premove);
    }
    if(move_socket){
        const message = {};
        message.match_id = match.match_id;
        message.type = "premove";
        message.premove = premove_data.premoves;

        var data_json = JSON.stringify(message);
        move_socket.send(data_json);
    }else{
        console.error("Socket not open");
    }
    
    return;
}

function handleAutoroll(){
    extra_data.autoroll = !extra_data.autoroll
    var message="";
    if(extra_data.autoroll){
        message = "Auto Roll On";
    }else{
        message = "Auto Roll Off";
    }
    set_board_message(message);
}

function resetMatch(){ // Currently not used
    match.match_id = null;
    match.player.token = null; 
    match.player.color = "";
    match.player_secret = "";
    console.log("RESETTING MATCH");
    localStorage.removeItem("current_match");
    router.replace("/");
}

function connectMatch(force_connect=false){
    if(move_socket && move_socket.readyState == 1){
        move_socket.close();
    }
    
    move_socket = new ReconnectingWebSocket(`${play_server}/match/${match.match_id}/`);
    move_socket.onopen = e => {
        extra_data.connected = false;
        
        // Recover the player secret from the local storage
        load_match();
        
        if(match.is_won()){
            router.replace({name:"post", params:{match_id: match.match_id}});
        }
            
        const data = {
            type: "connect", 
            match_id: match.match_id, 
            player_token: match.get_token(),
            commitment: match.get_commitment(),
            window_id: window.tabId,
            force_connect: force_connect,
        };
        move_socket.send(JSON.stringify(data));

        set_board_message("Connecting...", -1);
    };

    move_socket.onmessage = (e) => {
        const data = JSON.parse(e.data);
        
        if(data.type == "error"){
            console.log("Error:", data);
            // move_socket.close();
            if(data.message == "Match won"){
                router.replace({name: "post", params:{match_id: match.match_id}});
                return ;
            }
            // alert(data.message);
            // router.replace("/");
            
            return;
        }

        if(data.type != "pong"){
            console.log("RECV", data);
        }
        
        var could_move = false;
        all_players_connected();

        if(data.type == "connected"){
            const reconnect_dialog = document.getElementById("reconnect-dialog");
            if(reconnect_dialog.open){
                console.log("Closing dialog");
                reconnect_dialog.close();
            }
    
            extra_data.connected = true;
            match.player.color = data.color;

            set_board_message("Connected");
            
            load_match(data.match);

            clock_data.clock_config = data.clock_config;
            console.log("CONNECTED", match.is_won(), match);
            
            if(match.is_won()){
                router.replace({name:"post", params:{match_id: match.match_id}});
            }
            
            console.log("Players connected:", data.players_connected); 
            extra_data.players_connected = data.players_connected;
            
            const last_match_state = match.get_state();
            handleMove(last_match_state.toPositionString());
            
            if(ping_interval_id == null){
                ping_interval_id = setInterval(ping_server, 10*1000);
            }
        }
        if(data.type == "reconnect"){
            console.log("Reconnecting");
            load_match(data.match);

            set_board_message("Opponent connected")

            clock_data.clock_config = data.clock_config;
            extra_data.players_connected = data.players_connected

            // Do the initial move if needed IW->IB
            const last_state = match.get_state();
            if( extra_data.players_connected == 2 && last_state.game_state == "IW"){
                handleMove(last_state.toPositionString());
            }
        }
        if(data.type == "disconnect"){
            extra_data.players_connected = data.players_connected
            set_board_message("Opponent Disconnected", -1);
        }
        
        if(data.type == "chat"){
            data.color = data.sender;
            // chat_data.message = data;
        }    
        
        if(data.type == "pong"){
            latency_data.latency_w = data.latency_w;
            latency_data.latency_b = data.latency_b;
            latency_data.server = data.server;
            latency_data.last_update = data.timestamp;
            extra_data.players_connected = data.players_connected
            clearTimeout(ping_timeout);
            if(extra_data.board_message == "Disconnected"){
                extra_data.board_message = "";
            }
            
            extra_data.latency_data = data;
        }    

        if(data.type == "clock"){
            clock_data.clock = data.clock;
        }    
        if(data.type == "premove"){
            premove_data.premoves = data.premove;
            console.log(premove_data.premoves);
        }    
        if(data.type == "lastgame"){
            set_board_message("Last Game");
            extra_data.last_game = true;
        }
     
        if(data.type == "move"){
            let board = new BoardState(data["state"]);

            match.push_state(board, data["signature"]);
            could_move = handleMove(board.toPositionString());

            if(board.game_state == "RG"){
                set_board_message("Resigned Game");
            }else if(board.game_state == "RM"){
                set_board_message("Resigned Match");
            }else if(board.game_state == "FF"){
                set_board_message("Flagged");
            }

            premove_data.premoves = [];
            if(!could_move){
                apply_automatic_moves()
            }
        }

        if(data.type == "finalize" && !extra_data.finalized){
            if(data.extra_data){
                match.set_secret(data.extra_data["secret"], match.opponent);
                match.set_token(data.extra_data["token"], match.opponent);
            }else{
                console.log("Finalize does not contain secrets/tokens");
            }

            let board = new BoardState(data["state"]);
            match.push_state(board, data["signature"]);
            could_move = handleMove(board.toPositionString(), "finalize");
            
            const result = match.check_log();
             
            console.log("CLEAR FINALIZE");
            clearTimeout(finalize_timeout);
            clearInterval(ping_interval_id);
            set_board_message("Match finished", -1);
            extra_data.finalized = true;
            
            setTimeout( x => router.replace({name:"post", params:{match_id: match.match_id}}), 3000);
        }

        if(extra_data.players_connected < 2){
            set_board_message("Opponent Disconnected", -1);
        }
    };
    move_socket.onclose = (e) => {
        console.log("Socket closed");
        extra_data.connected = false; 
        clearInterval(ping_interval_id);
        ping_interval_id = null;
        set_board_message("Disconnected...", -1);

        // const reconnect_dialog = document.getElementById("reconnect-dialog");
        // if(reconnect_dialog != null && !reconnect_dialog.open){
        //     reconnect_dialog.showModal();
        // }
    };
    move_socket.onerror = (e) => {
        console.log("WEBSOCKET ERROR", e);
        
        const reconnect_dialog = document.getElementById("reconnect-dialog");
        if(reconnect_dialog != null && !reconnect_dialog.open){
            reconnect_dialog.showModal();
        }
    };
}

function apply_automatic_moves(){
    // apply forced moves
    const board = new BoardState(state.currentState);
    var action = null;
    if(board.game_state == "R" && match.player.color != board.color){
        const valid_states = Object.values(board.getValidStates());
        var next_state;
        
        if(valid_states.length <= 1){
            extra_data.forced_move = true;
            if(valid_states.length == 0){
                set_board_message("No Move", 1500);
                action = board;
            }else{

                set_board_message("Forced Move", 1500);
                console.log(valid_states);
                action = valid_states[0][0][0];
            }
            setTimeout(() => {
                    extra_data.forced_move = false;
                    handleMove(board.toPositionString(), action);
                }, 1500
            );
            return true;
        }
    }
    
    // Apply auto moves  ====> DEBUG code! allows a player to automatically play (bad) moves
    if(extra_data.auto_move && match.player.color != board.color){
        if(board.game_state == "R"){ // we are after a roll
            const valid_states = Object.values(board.getValidStates());
            console.log(valid_states);
            if(valid_states.length == 0){
                action = board;
            }else{
                const random_state = valid_states[Math.floor(Math.random() * valid_states.length)];
                action = random_state[0][0];
                console.log(action);
                set_board_message("Auto Move");
            }
        } else if(board.game_state == "D"){
            if(Math.random() < 0.60){
                action = "take";
            }else{
                action = "pass";
            }
        } else if(board.game_state == "C"){
            if(Math.random() < 0.10){
                action = "double";
            }else{
                action = "roll";
            }
        }
        
        setTimeout(() => {
                handleMove(board.toPositionString(), action);
            }, 1000
        );
    }
    
    return;
}

function getScore(match){
    const score = match.get_score(); 
    score.match_length = match.match_length;
    score.crawford = match.is_crawford();
    console.log(score);
    
    return score;
}

function reload(){
    if(extra_data.connected){
        const reconnect_dialog = document.getElementById("reconnect-dialog");
        if(reconnect_dialog != null && reconnect_dialog.open){
            console.log("Closing dialog");
            reconnect_dialog.close();
            return;
        }
    }
        
    location.reload();
}

function all_players_connected(){
    return extra_data.players_connected == 2;
}

var board_message_timeout_id = null;
function set_board_message(message, timeout=2000){
    clearTimeout(board_message_timeout_id);
    extra_data.board_message = message;
    if(timeout > 0){
        board_message_timeout_id = setTimeout(() => {
            extra_data.board_message = "";
        }, timeout);
    }
}

async function set_chatwindow_class(){
    await nextTick();
    const vw = Math.max(window.innerWidth || 0)
    const case_dimensions = document.getElementById("board-main").getBoundingClientRect();
    
    const chat_w = vw - case_dimensions.width;
    
    // chat_data.flex = chat_w > 300;
}
window.addEventListener("resize", () => set_chatwindow_class());

</script>
<template>
<Ping v-bind="latency_data"
/>

<dialog id="reconnect-dialog"
        class="rounded-lg p-8 items-center">
    <div class=" flex flex-col gap-y-8">
        <p>You have been disconnected from the game.</p>
        <button autofocus class="btn btn-blue mx-auto" @click="reload()">
            Reconnect
        </button>
    </div>
</dialog>

<div id="game" 
    class="game flex flex-col sm:flex-row relative"
    @keydown.enter.shift.stop="toggleChat()">
    <Board :positionString="state.currentState" 
           :player_color="match.player.color" 
           :all_players_connected="all_players_connected()"
           :differences="match.get_game().get_difference(null)"
           :clock_data="clock_data"
           :board_message="extra_data.board_message"
           :match_info="extra_data.match_info"
           :chat_status="(chatStore.unread > 0) ? 'new' : ''"
           :show_chat="true"
           :show_resign="true"
           :show_autoroll="true"
           :autoroll="extra_data.autoroll"
           :direction="match.player.color == 'B'? 'CCW' : 'CW'"
           :premoves="premove_data.premoves"
           :last_game="extra_data.last_game"
           :boardID="'main'"
           @move-end="handleMove"
           @premove="handlePremove"
           @autoroll="handleAutoroll"
           @togglechat="toggleChat()"
    >
    </Board>
    <GameSidebar 
           :player_color="match.player.color" 
           :match_info="extra_data.match_info"
           :chat_data="chat_data"
    />
</div>
</template>

<style scoped>
.game{
    height: calc(100svh - 0px);
    width: 100svw;
    max-width: 100%;
    max-height: 100%;
    background: var(--case-color);
    background: linear-gradient(0.25turn, var(--case-med-color), var(--case-color), var(--case-med-color));
    color: var(--text-color);
    overflow: hidden;
}
.overview{
    display:none;
    width:30em;
    overflow-y:auto;
    font-family: monospace;
}
</style>
