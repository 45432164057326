<script setup>
import {ref, watch, reactive, onMounted} from 'vue'
import {FlagIcon} from '@heroicons/vue/24/solid'

const props = defineProps({
    clock:{
        type: Object,
        default:{},
    },
    clock_config:{
        type: Object,
        default:{"reserve": -1, "delay": -1},
    },
    clock_color:{
        type: String,
        default:"W",
    },
    player_color:{
        type: String,
        default:"W",
    },
    pause:{
        type:Boolean,
        default:false,
    }
});

const data = reactive({
    "clock": {"W": 60, "B":60, "delay":10},
    "flagged": false,
});

var last_tick = null;
var tick_interval = null;

onMounted(() => {
    data.clock = Object.assign(data.clock, props.clock);
    tick();
    tick_interval = setInterval(() => {
        tick();
    },
    100);
});

watch(() => props.clock, async (newVal) => {
    data.clock = Object.assign(data.clock, newVal);
    data.flagged = false;
    if(tick_interval == null){
        last_tick = null;
        tick_interval = setInterval(() => {
            tick();
        },
        100);
    }
});

const emit = defineEmits([
    'flag'
]);

function tick(){
    if(props.clock_config["reserve"] < 0){
        return;
    }
    if(last_tick == null){
        last_tick = Date.now();
    }
    if(data.clock["W"] == "F" || data.clock["B"] == "F"){
        flag(); 
        return;
    }
    
    // update the clock
    const time_elapsed = (Date.now() - last_tick) / 1000; // we work in seconds

    if(data.clock.delay - time_elapsed > 0){
        data.clock.delay -= time_elapsed;
    }else{
        data.clock[props.clock_color] -= (time_elapsed - data.clock.delay); 
        data.clock.delay = 0;
    }
    
    if(data.clock[props.clock_color] <= 0 && data.clock.delay <= 0){
        data.clock[props.clock_color] = "F";
    }
    
    last_tick = Date.now();
}

function flag(){
    if(props.player_color == props.clock_color && ! data.flagged){    
        emit('flag', props.clock_color);
        data.flagged = true;
        clearInterval(tick_interval);
        tick_interval = null;
        last_tick = null;
    }
}

function get_clock(top_side=true){
    if(! data.clock){
        return 0;
    }
    if(props.player_color == "W"){
        if(top_side){
            return data.clock["B"];
        }else{
            return data.clock["W"];
        }
    }else{
        if(top_side){
            return data.clock["W"];
        }else{
            return data.clock["B"];
        }
    }
}

function get_delay(seconds){
    seconds = parseInt(seconds);
    const days = Math.floor(seconds / (24*60*60));
    const hours = Math.floor(seconds / (60*60));
    const minutes = Math.floor(seconds / 60);

    if( days > 0){
        return `${days}d`
    }
    else if( hours > 0){
        return `${hours}h`
    }
    else if( minutes > 5){
        return `${minutes}m`
    }else{
        return seconds.toFixed(0).toString().padStart(2, '0');
    }
}

function get_time(seconds){
    if(seconds == "F"){
        return "FF:FF";
    }
    const minutes = Math.floor((seconds / 60)).toString().padStart(2, "0");
    const sec = Math.floor(seconds % 60).toString();
    const tenths = Math.floor((seconds * 10) % 10).toString()
    const hundreds = Math.floor((seconds * 100) % 100).toString().padStart(2, "0")

    if( seconds >= 60){
        return `${minutes}:${sec.padStart(2, "0")}`
    }else if(seconds >= 10){
        return `${sec.padStart(2, "0")}.${tenths}`
    }else{
        return `${sec}.${hundreds}`
    }
}

function show_alert_color(seconds){
    if(props.clock_config <= 0){
        console.log("No clock config!");
        return false;
    }
    if(seconds < 3 && seconds > 0){
        return true;
    }
    if(data.clock.delay > 0){
        return false;
    }
    const alert_cutoff = Math.max(10, 2*props.clock_config.delay);
    const show_alert = seconds < alert_cutoff && (Math.floor(seconds) % 2) == 0;

    return show_alert;
}

function is_small(){
    const elem = document.getElementById("clock");
    if(elem == null){
        return false;
    }
    const board = elem.parentElement;

    return board.clientHeight < 500;
}

</script>

<template>
    <div class="text-board-text-color flex-col w-[6em]"
         id="clock"
         v-if="! is_small()"
    >
        <div class="px-0 md:px-2 py-0 md:py-1 text-xs md:text-xl font-mono text-center" 
             :class="{
                'border rounded md:border-2 md:rounded-lg': props.player_color != props.clock_color,
                'text-highlight-color': show_alert_color(get_clock(true)) && props.player_color != props.clock_color,
             }"
        >
            {{ get_time(get_clock(true))}}
        </div>
        <div class="font-mono text-base leading-tight md:leading-normal  md:text-4xl text-center" 
            v-if="data.clock.delay != undefined"
        >
            {{ get_delay(data.clock.delay) }}
        </div>
        <div class="px-1 md:px-2 py-0.5 md:py-1 text-xs md:text-xl font-mono text-center" 
             :class="{
                 'border-2 rounded-lg': props.player_color == props.clock_color,
                 'text-highlight-color': show_alert_color(get_clock(false)) && props.player_color == props.clock_color,
             }"
        >
            {{ get_time(get_clock(false)) }}
        </div>
            
    </div>
    <div class="text-stone-w-color flex-col" 
         id="clock"
         v-else
    >
        <div class="font-mono text-base leading-tight 
             text-center flex flex-col gap-y-4 gap-x-1 items-center justify-center
             w-[5em]" 
            v-if="data.clock.delay != null"
        >
        <div class="w-full" :class="{'border-2 rounded-lg px-2 py-1':  props.player_color != props.clock_color}">
            <span v-if="data.clock.delay > 0 && props.player_color != props.clock_color">
                {{ get_delay(data.clock.delay) }}
            </span>
            <span v-else
                  :class="{
                     'text-highlight-color': show_alert_color(get_clock(false)) && props.player_color != props.clock_color
                 }"
                >
                {{ get_time(get_clock(true))}}
            </span>
        </div>

        <div class="w-full" :class="{' border-2 rounded-lg px-2 py-1':  props.player_color == props.clock_color}">
            <span v-if="data.clock.delay > 0 && props.player_color == props.clock_color">
                {{ get_delay(data.clock.delay) }}
            </span>
            <span v-else
                  :class="{
                     'text-highlight-color': show_alert_color(get_clock(false)) && props.player_color == props.clock_color
                 }"
            >
                {{ get_time(get_clock(false))}}
            </span>
        </div>
        </div>
    </div>
</template>

<style scoped>
.arrow-up{
  display: inline-block;
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  
  border-bottom: 5px solid  var(--board-text-color);
}

.arrow-down{
  display: inline-block;
  width: 0; 
  height: 0; 
  border-left: 0.5em solid transparent;
  border-right: 0.5em solid transparent;
  
  border-top: 0.5em solid var(--board-text-color);
}

</style>
