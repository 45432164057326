<script setup>
/*
    Implementing the Game logic.

    TODO
    - Show that an opponent disconnected
    - 
*/
import Board from './Board.vue'
import Message from './Message.vue'
import GameSidebar from './GameSidebar.vue'

import { Match } from '../assets/js/match.js'
import { StateMachine } from '../assets/js/statemachine.js'
import { Bot } from '../assets/js/bots.js'
import {BoardState} from '../assets/js/board.js'

import {ref, computed, reactive, onMounted, onUnmounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import {useSSEStore } from '@/stores/ssestore.js'
const sseStore = useSSEStore();


const route = useRoute();
const router = useRouter();

const play_server = import.meta.env.VITE_WEBSOCKET_SERVER;
const app_server = import.meta.env.VITE_APP_SERVER;

var finalized = false;

const state = reactive({
    currentState: null,
    score: {},
});

const extra_data = reactive({
    message : "",
    board_message : "",
    match_info: {},
});

const premove_data = reactive({
    premoves: [],
});

const clock_data = reactive({
    clock: {"W":-1, "B":-1, "delay":-1},
    clock_config: {},
});

const bot_data = reactive({
    move_callback: null, 
});

var match = new Match();
const state_machine = reactive(new StateMachine());
var bot = null;
var bot_id = null;

let move_socket = null;
var finalize_timeout = null;

const bot_event_func = (e) => onMessage(e.detail)

onMounted(() => {
    bot_id = route.params.bot_id;
    bot = new Bot(bot_id);
    bot.bot.app_server_url = app_server;
    get_bot_info();

    sseStore.connect();
    sseStore.addListener("analysis", (data) => bot.bot.handle_analysis(data));
    
    match = new Match("W", bot.bot.match_length); 
    match.player.color = "W";
    extra_data.match_info.points = match.match_length;

    state.currentState = match.get_state().toPositionString();
    state_machine.player_color = "W";
    state_machine.roll_dice_callback = (dice, move_counter, distinct) => match.get_dice(dice, move_counter, distinct);

    extra_data.match_info.black = {
        username: bot.bot.name,
        elo: bot.bot.elo,
        rating: bot.bot.elo,
    };
    extra_data.match_info.white = {
        username: "Anonymous",
        elo: 800,
        rating: 800,
    };
    
    // register the message handler
    document.removeEventListener("bot-message", bot_event_func);
    document.addEventListener("bot-message", bot_event_func);
    
    userStore.loadUser().then(() => {
        userStore.loadPreferences().then( () => {
            userStore.applyBoardPreferences(document.getElementById("game"))
            if(userStore.preferences.board.animation_duration){
                bot.delay = parseFloat(userStore.preferences.board.animation_duration) * 1000;
            }
        });
        extra_data.match_info.white = {
            username: userStore.info.username,
            avatar: userStore.info.avatar,
            elo: userStore.info.elo,
            rating: userStore.info.rating,
        };
    });
});

onUnmounted( () => {
    document.removeEventListener("bot-message", bot_event_func);
    bot.remove_eventlistener();
});

function get_match_info(match_id){
    extra_data.match_info = bot.get_matchinfo();
}

async function get_bot_info(){
    const response = await fetch(app_server + `/bot/${bot_id}/`, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const data = await response.json();
    console.log("Bot Data:", data);
    if(data.status == "success"){
        extra_data.match_info.black = data.info;
    }
}

function getDiceString(move_id){
    return match.get_dice_string(move_id);
}

function getMoveString(move_id){
    return match.get_move_string(move_id);
}

function sendMessage(positionString, type="move", extra_data=null){
    // send the move to the other player
    if(finalized || match.is_won()){
        return;
    }
    
    let signature = match.sign_position(positionString);
    const data = {
        type: type, 
        match_id: match.match_id, 
        state: positionString,
        signature: signature,
        extra_data: extra_data,
    };
    if(type == "move"){
        match.push_state(new BoardState(positionString), signature);
    }
    
    document.dispatchEvent( new CustomEvent("player-message", {
        detail: data,
    }));

    if(type == "finalize"){ // We wait for 10 seconds for the opponent to finalize, otherwise move on
        extra_data.board_message = "Finalizing...";
        finalize_timeout = setTimeout(() => {
                extra_data.board_message = "Redirecting";
                router.replace({name:"post", params:{match_id: match.match_id}})
            }, 10000
        );
    }
}

function handleMove(positionString, action=null){
    if(finalized){
        return;
    }
    const boardstate = new BoardState(positionString);
    var new_boardstate = state_machine.next_state(boardstate, action);     
    
    if(new_boardstate == null){
        new_boardstate = boardstate;
    }else{
        if(new_boardstate.game_state == "F" && !finalized){
            const extra_data = match.to_json().player;
            finalized = true;
            // sendMessage(new_boardstate.toPositionString(), "final", extra_data);
            finalize_match();
            return true;
        }else{
            sendMessage(new_boardstate.toPositionString());
            return true;
        }
    }
    state.currentState = new_boardstate.toPositionString();
    return false;
}

function resetMatch(){
    match.match_id = null;
    match.player.token = null; 
    match.player_color = "";
    match.player_secret = "";
    localStorage.removeItem("current_match");
    router.replace("/");
}

function onMessage(data){
    if(data.type == "error"){
        console.log("Error:", data);
        resetMatch();
        return;
    }
    
    if(data.type == "chat"){
        // chat_data.message = data;
    }    
    if(data.type == "premove"){
        premove_data.premoves = data.premoves;
        return;
    }    
     
    var could_move = false;
    if(data.type == "move" || data.type == "final"){
        let board = new BoardState(data["state"]);
        if(board.game_state == "IB" || board.color == "B"){
            match.push_state(board, data["signature"]);
        }
        could_move = handleMove(board.toPositionString());
    }

    if(data.type == "final"){
        match.set_secret(data.extra_data["secret"], match.opponent);
        match.set_token(data.extra_data["token"], match.opponent);
        // TODO send to server to do analysis/log the match / etc
        
        const result = match.check_log();
         
        clearTimeout(finalize_timeout);
        return;
    }
    
    // apply forced moves
    const board = new BoardState(state.currentState);
    var action = null;
    if(!could_move && board.game_state == "R" && match.player.color != board.color){
        const valid_states = Object.values(board.getValidStates());
        var next_state;
        
        if(valid_states.length <= 1){
            if(valid_states.length == 0){
                extra_data.board_message = "No Move";
                action = board;
            }else{
                extra_data.board_message = "Forced Move";
                action = valid_states[0][0][0];
            }
            // match.push_state(board);
            setTimeout(() => {
                    handleMove(board.toPositionString(), action);
                    extra_data.board_message = "";
                }, 1200
            );
            return;
        }
    }
    
    return;
}

async function finalize_match(){
    const response = await fetch(app_server + `/bot/${bot_id}/match/create/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
            "match": match.to_json(),
        }),
    });
    const match_data = await response.json();
    router.replace({name:"post", params:{match_id: match_data.match_id}});
}


</script>
<template>
<Message />
<div id="game" 
    class="game flex flex-row items-center">
    <Board :positionString="state.currentState" 
           :player_color="match.player.color" 
           :state_machine="state_machine"
           :all_players_connected="true"
           :differences="match.get_game().get_difference(null)"
           :clock_data="clock_data"
           :board_message="extra_data.board_message"
           :match_info="extra_data.match_info"
           :show_resign="true"
           :premoves="premove_data.premoves"
           @move-end="handleMove"
    >
    </Board>
    <GameSidebar 
           :player_color="match.player.color" 
           :match_info="extra_data.match_info"
    />
</div>
</template>

<style scoped>
.game{
    height: calc(100svh);
    width: 100svw;
    max-width: 100%;
    max-height: 100%;
    --case-med-color: color-mix(in srgb, var(--case-color) 80%, white);
    background: var(--case-color);
    background: linear-gradient(0.25turn, var(--case-med-color), var(--case-color), var(--case-med-color));
    /*background-color: linear-gradient(var(--case-med-color), var(--case-color), var(--case-med-color));*/
    overflow: hidden;
}
</style>
